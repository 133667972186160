module.exports = {
    defaultLocale: 'fr',
    locales: ['en', 'fr'],
    pages: {
        '*': [
            'common',
            'emailVerification',
            'firebaseError',
            'footer',
            'modelType',
            'navBar',
            'notifications',
            'offer',
            'search',
            'signup',
        ],
        '/': ['books', 'home', 'modelType'],
        '/cgu': ['cgu'],
        '/cgv': ['cgv'],
        '/email-action-handler': ['emailActionHandler', 'profile'],
        '/legal-charts': ['legalCharts', 'modelType'],
        '/legal-notice': ['legalNotice'],
        '/password-recovery': ['passwordRecovery', 'profile'],
        '/profile': ['profile'],
        '/profile/deleted': ['accountDeleted', 'profile'],
        '/saved-searches': ['savedSearches', 'modelType'],
        '/search': [
            'article',
            'decision',
            'glossary',
            'keyword',
            'juryPosition',
            'language',
            'lawScopes',
            'lawText',
            'mainKeywords',
            'modelType',
            'publicationSheet',
        ],
        '/signin': ['profile', 'signin'],
        '/signup': ['profile', 'signup', 'signin'],
        404: ['common'],
        'rgx:^/articles': ['article', 'common', 'modelType'],
        'rgx:^/books': [
            'books',
            'codeArticle',
            'freemium',
            'language',
            'modelType',
            'profile',
            'signin',
        ],
        'rgx:^/decisions': [
            'decision',
            'freemium',
            'juryPosition',
            'keyword',
            'modelType',
            'profile',
            'signin',
            'statisticEntry',
        ],
        'rgx:^/glossary': [
            'glossary',
            'modelType',
            'language',
            'lawScopes',
            'mainKeywords',
            'treeId',
        ],
        'rgx:^/laws': ['lawText', 'modelType'],
        'rgx:^/payment': ['payment'],
        'rgx:^/search/icicle': [
            'modelType',
            'books',
            'language',
            'lawScopes',
            'publicationSheet',
            'article',
            'decision',
            'glossary',
        ],
        'rgx:^/search/tree-view': [
            'books',
            'decision',
            'modelType',
            'publicationSheet',
            'treeView',
        ],
        'rgx:^/sitemap/decisions': ['sitemap'],
        'rgx:^/statistics': ['statistic', 'modelType'],
    },
};
